<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!-- 右边主要区 -->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!-- 右边头部搜索区 -->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 名称 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 类型 -->
                                <el-form-item :label="columnInfo.category.label" prop="category">
                                        <el-input v-model="queryParams.param.category" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 内容 -->
                                <el-form-item :label="columnInfo.content.label" prop="content">
                                        <el-input v-model="queryParams.param.content" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 状态 0：停用 1：启用 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                        <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="clickSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 右边中部功能区 -->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    v-permissionDisabled="'data:promptTemplate:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="提示词模板表" module-name="data" table-name="promptTemplate" :permission-code="'data:promptTemplate:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="提示词模板表" module-name="data" table-name="promptTemplate" :select-list="selectList" :permission-code="'data:promptTemplate:export'"></excel-export-plus>
                        </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!-- 右边中部表格区 -->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 名称 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible">
                                        </af-table-column>
                                        <!-- 类型 -->
                                        <af-table-column align="center" prop="category" :label="columnInfo.category.label" v-if="columnInfo.category.visible">
                                        </af-table-column>
                                        <!-- 内容 -->
                                        <af-table-column align="center" prop="content" :label="columnInfo.content.label" v-if="columnInfo.content.visible">
                                        </af-table-column>
                                        <!-- 状态 0：停用 1：启用 -->
                                        <af-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                            <template v-slot="scope">
                                                <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data" show-tag></enum-dict-label>
                                            </template>
                                        </af-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="225" fixed="right">
                                    <template v-slot="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'data:promptTemplate:update'">编辑</el-button>
                                        <el-button @click="clickDel(scope.row)" type="danger" size="mini" v-permissionDisabled="'data:promptTemplate:remove'">删除</el-button>
                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!-- 右边底部分页区 -->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="data"
                                         table-name="promptTemplate"
                                         :del-all-permission-code="'data:promptTemplate:removes'"
                                         :state-permission-code="'data:promptTemplate:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <promptTemplateForm/>
    </div>
</template>

<script>
    /**
     * 提示词模板表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2025-02-23
     */
    import promptTemplateForm from "./PromptTemplateForm"
    import {mapState, mapMutations, mapActions} from "vuex";
    import {registerStore,unregisterStore} from "@/utils/common";
    import promptTemplateDataProvider from "./PromptTemplateDataProvider";

    export default {
        components: {
            // 表单弹出组件
            promptTemplateForm
        },
        data() {
            return {
                // 每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                // 批量操作选中list（分页控件）
                selectList: [],
                // 展示搜索区（right-toolbar）
                showSearch: false,
                // 导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                // 表格数据
                tableData: state => state.data.promptTemplate.tableData,
                // 下拉选项
                options: state => state.data.promptTemplate.options,
                // 共有数据条数（分页控件）
                totalCount: state => state.data.promptTemplate.totalCount,
                // 列信息
                columnInfo: state => state.data.promptTemplate.columnInfo,
                // 查询参数
                queryParams: state => state.data.promptTemplate.queryParams,
                // 时间范围
                dateRange: state => state.data.promptTemplate.dateRange,
                // 更多按钮信息
                moreButtonProps: state => state.data.promptTemplate.moreButtonProps,
            })
        },
        // 生命周期create函数
        created() {
            // 注册vuex模块
            registerStore('data','promptTemplate',promptTemplateDataProvider);
            // 创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            // 获取OptionList
            this.getOptionList();
        },
        // 生命周期销毁函数
        destroyed() {
            // 解绑vuex模块
            unregisterStore('data','promptTemplate');
        },
        methods: {
            ...mapMutations({
                clickEdit: 'data/promptTemplate/clickEdit',
                clickAdd: 'data/promptTemplate/clickAdd',
            }),
            ...mapActions({
                clickDel: 'data/promptTemplate/handleDel',
                handleTableSearch: 'data/promptTemplate/handleTableSearch',
                getOptionList: 'data/promptTemplate/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 搜索按钮点击（搜索区） */
            clickSearch() {
                this.queryParams.currPage = 1;
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
