<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="提示词模板表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 名称 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 类型 -->
                        <el-form-item :label="columnInfo.category.label" prop="category">
                                <el-input v-model="ruleForm.category" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 内容 -->
                        <el-form-item :label="columnInfo.content.label" prop="content">
                                <el-input v-model="ruleForm.content" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 状态 0：停用 1：启用 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                                <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	提示词模板表表单
     * 	@author PeterLee
     * 	@since 2025-02-23
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'promptTemplateForm',
        // 计算操作
        computed: {
            ...mapState({
                // 校验规则
                rules: state => state.data.promptTemplate.rules,
                // 表格数据
                tableData: state => state.data.promptTemplate.tableData,
                // 表单信息
                formInfo: state => state.data.promptTemplate.formInfo,
                // 下拉选项
                options: state => state.data.promptTemplate.options,
                // 列信息
                columnInfo: state => state.data.promptTemplate.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'data/promptTemplate/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'data/promptTemplate/submitAdd',
                submitEdit: 'data/promptTemplate/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
