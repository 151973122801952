/**
 * 提示词模板表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2025-02-23
 */
const state = {
   //表单校验规则
   rules: {
      //名称
      name:[{
         required: true,
         message: '请输入名称',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //类型
      category:[{
         required: true,
         message: '请输入类型',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //内容
      content:[{
         required: true,
         message: '请输入内容',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //状态 0：停用 1：启用
      state:[{
         required: true,
         message: '请选择状态 0：停用 1：启用',
         trigger: 'change'
       }],
   },
   //列信息
   columnInfo: {
       //名称
       name:{
            visible:true,
            label:"名称"
       },
       //类型
       category:{
            visible:true,
            label:"类型"
       },
       //内容
       content:{
            visible:true,
            label:"内容"
       },
       //状态 0：停用 1：启用
       state:{
            visible:true,
            label:"状态 0：停用 1：启用"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {
   },
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //名称
         name: undefined,
         //类型
         category: undefined,
         //内容
         content: undefined,
         //状态 0：停用 1：启用
         state: undefined,
      }
   },
   //时间范围
   dateRange: {
   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state