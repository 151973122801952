import state from "./PromptTemplateState";
import { addData, getPage, removeById, removeData, getList, updateData, updateState } from "@/api/baseApi";
import { Message, MessageBox } from 'element-ui'
import {handleTree,setDateRange} from "@/utils/common";

/**
 * 提示词模板表数据提供者（vuex数据纳入原则：需要异步请求和与其他组件公用的属性和方法都应纳入其中，每个vue单独的不纳入）
 *
 * @author PeterLee
 * @since 2025-02-23
 */
export default {
    namespaced: true,
    state: state,
    getters: {},
    mutations: {
        /** 设置表单弹窗显示 */
        setDialogVisible(state, visible) {
            state.formInfo.dialogVisible = visible;
        },
        /** 点击提交 */
        clickAdd(state) {
            state.formInfo.isAdd = true;
            state.formInfo.dialogVisible = true;
        },
        /** 点击编辑 */
        clickEdit(state, data) {
            state.formInfo.isAdd = false;
            state.formInfo.dialogVisible = true;
            state.formInfo.editData = data;
        },
    },
    actions: {
        /** 添加提交后执行内容(异步网络请求) */
        submitAdd({commit, dispatch}, data) {
            addData("data", "promptTemplate", data).then(res => {
                if (res.code === 200) {
                    //关闭弹出层并刷新树
                    dispatch('handleTableSearch')
                    Message.success("添加数据组件表成功")
                    commit('setDialogVisible', false)
                }
            });
        },
        /** 删除(异步网络请求)  */
        handleDel({dispatch}, data) {
            //弹出确认框
            MessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:'warning'
            }).then(() => {
                //确认点击删除后，请求后端
                removeById("data", "promptTemplate", data.id).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        dispatch('handleTableSearch')
                        //顶部显示提示
                        Message.success("删除成功！")
                    }
                })
            }).catch(() => {
                Message.info("已取消删除")
            });
        },
        /** 处理表格查询(异步网络请求)  */
        handleTableSearch({state}) {
            let param = state.queryParams;
            for (let key in state.dateRange) {
                param = setDateRange(param, state.dateRange[key], key);
            }
            getPage("data", "promptTemplate", param).then(res => {
                if (res.code === 200) {
                    state.tableData = res.data.list;
                    state.totalCount = res.data.totalCount;
                }
            })
        },
        /** 获取OptionList(异步网络请求) */
        getOptionList({state}) {
        },
        /** 编辑提交后执行内容 (异步网络请求)*/
        submitEdit({commit, dispatch}, data) {
            updateData("data", "promptTemplate", data).then(res => {
                if (res.code === 200) {
                    //关闭弹出层并刷新树
                    dispatch('handleTableSearch')
                    Message.success("修改数据组件表信息成功")
                    commit('setDialogVisible', false)
                }
            });
        },
    },
    modules: {}
}